import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import styles from './index.module.css';

const NowPage = () => (
  <Layout>
    <SEO title="What I'm doing now" />
    <h2>What I&#39;m doing now</h2>
    <p>
        I recently started a new job as a software engineer at Google.
        More specifically, I&#39;m working on web projects at YouTube.
        It&#39;s been really fascinating to see how a platform operates at such
        a wild scale. It&#39;s also been inspiring to work alongside so many
        brilliant people and I&#39;m really looking forward to leveling up my
        engineering chops over the next few years.
    </p>
    <p>
        I also recently traveled to Japan and caught the cherry blossoms in Kyoto
        and Tokyo. Review: Tokyo is my new favorite city.
    </p>
    <p className={styles.date}>
        Last updated May 19, 2019.
    </p>
  </Layout>
);

export default NowPage;
